.footer-mp {
  box-sizing: border-box;
  width: 90vw;
  padding: 0 0 6px;
  max-width: 688px;
 
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-wrap: wrap;
  
  justify-self: flex-end;
  background-color: transparent;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;

  &.with-copyright {
    max-width: 900px;
  }
  &.center {
    -webkit-justify-content: center;
    justify-content: center;
  }

  img, p {
    margin: 3px;
  }

  img { flex: 0 0; }

  .uw, .ucsf {
    height: 70px;
  }

  .va {
    height: 100px;
  }

  .healthnet {
    height: 200px;
  }

  .path4you { 
    height: 60px;
  }

  .copyright { 
    color: white;
    font-size: 13px;
    width: 100%;
    text-align: center;
   }
}

@media only screen and (min-width: 835px){
  .footer-mp { margin-bottom: 25px;}
}

@media only screen and (max-width: 719px){
  .footer-mp {
    width: 95vw;

    .copyright { 
      flex: 2 0 90vw;
      margin-top: 20px;
    }
  }
}

